.YearlyAllowanceBarHeaderContainer {
  padding: 0 50px;
  width: 100%;
}

@media screen and (max-width: 869px) {
  .YearlyAllowanceBarHeaderContainer {
    padding: 0 10px;
  }
}
