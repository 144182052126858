
.disabled-checkbox .ag-checkbox-input-wrapper {
    pointer-events: none;  // Disable interaction
    opacity: 0.5;         // Make it look grayed out
}
.page-header {
    padding-bottom: 20px !important
}
.subtitle {
    font-weight: bold;
    font-size: 22px;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    color: black;
}
.page-hr {
    background-color: #ddd;
}
.content {
    background-color: transparent;
    border: lightgray solid 1px;
    border-radius: 10px;
}

.textSm {
    font-size: 12px;
    vertical-align: middle;
    line-height: 20px;
}

.textEmphasis {
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
}


.textGray {
    color: #999;
}

.censusReviewHeaderCell {
    background-color: transparent;
    border-bottom: none;
    border-right: none;
    font-weight: 600;
    font-size: 14px;
}
.censusReviewGrid {
    border: none;
    background-color: transparent;

    .ag-paging-panel {
        border-top: none !important;
        margin-top: 20px !important;
    }


    .ag-root-wrapper {
        border: none;
        background-color: transparent;
    }

    .ag-header {
        background-color: transparent;
        border-bottom: none;

        .ag-header-cell {
            background-color: transparent;
            border-right: none;
            font-weight: 600;
            font-size: 14px;
        }



        .ag-header-cell-text {
            background-color: transparent;
            border-bottom: none;
        }
    }

    .ag-row {
        background-color: white !important;
        border-radius: 5px;
        border-color: #f6f9fc;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
        transition: all 0.2s ease;
        display: flex;
        align-items: center;
        min-height: 64px;
        border-width: 5px;

        .ag-cell {
            border: none !important;
            padding: 16px 20px;
            line-height: 24px;
        }
    }

    .ag-row-hover {
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.12);
    }

    .ag-row-selected {
        box-shadow: 0 0 0 2px #1890ff;
    }
}
