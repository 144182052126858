.young {
    font-family: "Young Serif", serif;
    font-weight: 400;
    font-style: normal;
}

.page {
    margin: auto;
    padding: 40px;
    width: clamp(500px, 100%, 1280px)
}

.header {
    padding-bottom: 100px;
}