.contact {
  background: #fff;
  width: clamp(200px, 100%, 240px);
  position: relative;
  border: 1px solid #e0eaf1;
  &.primary {
    background: #f6f9fc;
  }
  .badge {
    background: var(--main-primary-color);
    border-radius: 17px;
    color: white;
    padding: 3px 12px;
    position: absolute;
    top: -15px;
  }
}
