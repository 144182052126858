.default {
  border-bottom: 2px solid white;
  font-weight: normal;
  padding: 0;
}

.active {
  color: #003146;
  composes: default;
  font-weight: bold;
  border-bottom: 2px solid #003146;
}

.tabBody {
  background-color: #f0f3f6;
  border-radius: 8px;
  padding: 15px;
  width: 100%;
}
