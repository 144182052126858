.ruleGroup {
    border-width: 1px;
    flex-direction: column;
    gap: .5rem;
    padding: .5rem;
    display: flex;
    border-color: rgb(223 223 223 / 32%);
    background: #e4e4e433;
}

.ruleGroup .ruleGroup-body {
    flex-direction: column;
    gap: .5rem;
    display: flex
}

.queryBuilder {
    max-width: 450px;
}

.ruleGroup-addGroup, .ruleGroup-addRule, .rule-remove {
    display: none;
}