.YearlyAllowanceBarBodyContainer {
  padding: 0 50px;
  max-height: 370px;
  overflow-y: auto;
}

.YearlyAllowanceBarBodyViewMore {
  padding: 0.25em 4em;
}

@media screen and (max-width: 869px) {
  .YearlyAllowanceBarBodyContainer {
   padding: 0 10px;
  }
}

@media screen and (max-width: 567px) {
   .YearlyAllowanceBarBodyInnerContainer {
    flex-direction: column;
  }

  .YearlyAllowanceBarBodyView {
    margin-top: 10px;
  }
}
