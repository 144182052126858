.default {
  background-color: #fff;
  border-collapse: collapse;
  border-radius: 6px;
  width: 100%;

  td {
    padding: 0;
    text-align: center;
    line-height: 1.2;
  }

  th {
    border: none;
    line-height: 1.2;
    padding: 15px;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;

    span {
      font-weight: 500;
    }
  }

  thead > tr {
    border-bottom: 1px solid #dbe2e8;
  }

  thead > tr {
    border-bottom: 1px solid #dbe2e8;
  }

  tbody tr {
    padding-left: 15px;

    &.highlight:nth-child(even),
    &.highlight:nth-child(odd) {
      background-color: #e0f3ee;
    }

    &:nth-child(odd) {
      background-color: white;
    }

    &:nth-child(even) {
      background-color: #f2f6f9;
    }
  }
}
