.mimicHixmeInputStyle {
    height: 42px;
    font-size: 16px;
    font-family: 'Helvetica Now Display', 'sans-serif';
    box-shadow: inset 0 1px 1px 0 #DBE2E8;
    border: 1px solid #B9C5CD;
    border-radius: 4px;
    transition: border 0.2s ease-in-out;
    margin-bottom: 5px;

    &:hover {
        border-color: #B9C5CD;
    }
    
    &:focus-within {
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%), 0 2px 6px 0 rgb(0 0 0 / 14%) !important;
        border-color: #B9C5CD !important;
    }
}