.default {
  position: fixed;
  height: 150px;
  width: 100%;
  opacity: .9;
  z-index: 10000;
  transition: top .5s ease;

  ul {
    list-style-type: none;
    padding: 0px;
    margin: 0;
  }
}

.closed {
  @extend .default;
  top: -150px;
}

.opened {
  @extend .default;
  top: 0px;
}
