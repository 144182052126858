$transitionDuration: 0.3s;
$transitionCurve: ease-in-out;

$primaryClr: #00a0a8;
$primaryHoverClr: #4cbcc2;
$primaryDisabled: #9bc5ff;

$dangerClr: #CE6C6D;
$dangerHoverClr: #E07B7C;
$dangerDisabled: #F4D3D4;;

$secondaryClr: #f0f0f0;
$secondaryHoverClr: #d9d9d9;
$secondaryDisabled: #f5f5f5;

$specialClr: #003146;
$specialHoverClr: #4c6e7d;
$specialDisabled: #7f98a2;

$btnBorderRadius: 6px;
$btnPadding: 8px 16px;
$largeBtnFont: 16px;
$medBtnFont: 14px;

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: $btnBorderRadius;
  border: none;
  padding: $btnPadding;
  box-shadow: none;
  cursor: pointer;
  transition: all $transitionCurve $transitionDuration;
  gap: 8px;

  &:disabled {
    cursor: not-allowed;
  }
}

.bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

// Sizes
.large {
  font-size: $largeBtnFont;
  min-height: 40px;
}
.medium {
  font-size: $medBtnFont;
  min-height: 36px;
}

// Types
// Flat buttons
.primary-flat {
  background-color: $primaryClr;
  color: #fff;
  svg g path {
    fill: #fff;
  }
  &:hover,
  &:focus {
    background-color: $primaryHoverClr;
  }

  &:disabled {
    background-color: $primaryDisabled;
    color: #c7deff;
    svg g path {
      fill: #c7deff;
    }
  }
}

.secondary-flat {
  background-color: $secondaryClr;
  color: #262626;
  svg g path {
    fill: #262626;
  }
  &:hover,
  &:focus {
    background-color: $secondaryHoverClr;
  }

  &:disabled {
    color: $secondaryHoverClr;
    background-color: $secondaryDisabled;
    svg g path {
      fill: $secondaryHoverClr;
    }
  }
}

.special-flat {
  background-color: $specialClr;
  color: #fff;
  svg g path {
    fill: #fff;
  }
  &:hover,
  &:focus {
    background-color: $specialHoverClr;
  }

  &:disabled {
    background-color: $specialDisabled;
    color: $specialHoverClr;
    svg g path {
      fill: #cbf3cf;
    }
  }
}

// Outline types
.primary-outline {
  background-color: #fff;
  border: 1px solid $primaryClr;
  color: $primaryClr;
  svg g path {
    fill: $primaryClr;
  }
  &:hover,
  &:focus {
    border-color: $primaryHoverClr;
    color: $primaryHoverClr;
    svg g path {
      fill: $primaryHoverClr;
    }
  }

  &:disabled {
    border-color: $primaryDisabled;
    color: $primaryDisabled;
    svg g path {
      fill: $primaryDisabled;
    }
  }
}

.secondary-outline {
  background-color: #fff;
  border: 1px solid #bfbfbf;
  color: #262626;
  svg g path {
    fill: #262626;
  }
  &:hover,
  &:focus {
    border-color: #8c8c8c;
    background-color: #fcfcfc;
  }

  &:disabled {
    border-color: #f0f0f0;
    background-color: #fff;
    color: #acacac;
    svg g path {
      fill: #acacac;
    }
  }
}

.special-outline {
  background-color: #fff;
  border: 1px solid $specialClr;
  color: $specialClr;
  svg g path {
    fill: $specialClr;
  }

  &:hover,
  &:focus {
    border-color: $specialHoverClr;
    color: $specialHoverClr;
    svg g path {
      fill: $specialHoverClr;
    }
  }

  &:disabled {
    border-color: $specialDisabled;
    color: $specialHoverClr;
    svg g path {
      fill: $specialDisabled;
    }
  }
}

// Link buttons
.link {
  background-color: transparent;
  color: #1677ff;
  svg g path {
    fill: #1677ff;
  }
  &:hover {
    color: #1365d9;
    svg g path {
      fill: #1365d9;
    }
  }
  &:disabled {
    color: #c7deff;
    svg g path {
      fill: #c7deff;
    }
  }
}

// Ghost btn - Flat btn with white bg
.ghost {
  background-color: #fff;
  color: #262626;
  svg g path {
    fill: #262626;
  }
  &:hover,
  &:focus {
    background-color: #f5f5f5; }

  &:disabled {
    background-color: #f0f0f0;
  }
}
// Danger Flat - Reject/Destructive actions
.danger-flat {
  background-color: $dangerClr;
  color: #fff;

  svg g path {
    fill: #fff;
  }

  &:hover,
  &:focus {
    background-color: $dangerHoverClr;
  }

  &:disabled {
    background-color: $dangerDisabled;
    color: #f8d7da; // Soft red for disabled state
    svg g path {
      fill: #f8d7da;
    }
  }
}

// Danger Flat - Reject/Destructive actions
.black-flat {
  background-color: black;
  color: #fff;

  svg g path {
    fill: #fff;
  }

  &:hover,
  &:focus {
    background-color: black;
    opacity: .8;
  }

  &:disabled {
    background-color: dimgray;
    opacity: .5;
    color: #f8d7da; // Soft red for disabled state
    svg g path {
      fill: #f8d7da;
    }
  }
}


// Danger Outline
.danger-outline {
  background-color: #fff;
  border: 1px solid $dangerClr;
  color: $dangerClr;

  svg g path {
    fill: $dangerClr;
  }

  &:hover,
  &:focus {
    border-color: darken($dangerClr, 10%);
    color: darken($dangerClr, 10%);
    background-color: $dangerHoverClr;
  }

  &:disabled {
    border-color: $dangerDisabled;
    color: lighten(#CE6C6D, 20%);
    background-color: #fff;
    cursor: not-allowed;

    svg g path {
      fill: lighten($dangerClr, 20%);
    }
  }
}


// Ensures the button text remains visible but slightly dimmed when loading
.buttonContent {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

// When loading, reduce text visibility slightly
.loading .buttonContent {
  opacity: 0.5;
}

// Spinner overlay
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 0.6s linear infinite;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
}

