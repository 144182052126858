.default {
  width: 100%;

  .homeLink {
    a:hover {
      opacity: .5;
    }

    a:focus {
      text-decoration: none;
    }
  }

  .separator {
    width: 2px;
    height: 38px;
    background-color: #f2f6f9;
  }

  .circleButton {
    cursor: pointer;
    display: flex;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    box-shadow: 0px 3px 10px 0px rgba(170,181,189,0.50);
    align-items: center;
    justify-content: center;
  }
}

.logo {
  height: 36px;
  position: relative;
  top: -4px;
}